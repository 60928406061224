/* sidebar  */
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --bg-card: #171f2a;
    --bg-navbar: #212b39;
    --main-text-color: #d2d9e4;
    --primary-color: #e66464;
    --secondary-color: #fc774a;
    --border-color: #424a55;
}

li {
    list-style: none;
}

h1 {
    font-weight: 600;
    font-size: 1.5rem;
}

.wrapper {
    display: flex;
    height: 100vh;
    position: fixed;
    z-index: 9999;
}

.main {
    position: relative;
    transition: all 0.35s ease-in-out;
    overflow: hidden;
}

#sidebar {
    width: 90px;
    min-width: 70px;
    z-index: 1000;
    transition: all .25s ease-in-out;
    background-color: #0e2238;
    display: flex;
    flex-direction: column;
}

.main__wrap {
    padding: 10px 0px 0px 0px;
    /* background-color: var(--bg-body); */
    transition: all .3s ease;
}

.sidebar__wrap.expand+.main__wrap {
    width: calc(100% - 235px);
    margin-left: auto;
}

.sidebar__wrap+.main__wrap {
    width: calc(100% - 95px);
    margin-left: auto;
}

#sidebar.expand {
    width: 232px;
}

.toggle-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}

.toggle-btn i {
    font-size: 1.5rem;
    color: #FFF;
}

.sidebar-logo {
    margin: auto 0;
}

.sidebar-logo a {
    text-decoration: none;
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;

}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}

.sidebar-nav {
    padding: 1rem 0;
    flex: 1 1 auto;
}

.navlink {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link i {
    font-size: 1.1rem;
    margin-right: .75rem;
}

a.sidebar-link:hover {
    background-color: rgba(255, 255, 255, .075);
    border-left: 3px solid #3b7ddd;
}

.sidebar-item {
    position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #0e2238;
    padding: 0;
    min-width: 15rem;
    display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}


/* login and signup */

/* .login-page {
    width: 25%;
    margin-top: 180px;
} */

.sign-page {
    width: 25%;
    margin-top: 100px;
}

.custom-overflow {
    overflow: hidden;
}

.main__wrap {
    overflow: hidden;
}

.form-controlinput:focus {
    border: none;
    outline: none !important;
}

.bind_details {
    margin: auto !important;
}

.table-sec {
    overflow-x: hidden !important;
}

/* .transactions{
overflow-x: scroll !important;
} */
/* sidebar  */
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --bg-card: #171f2a;
    --bg-navbar: #212b39;
    --main-text-color: #d2d9e4;
    --primary-color: #e66464;
    --secondary-color: #fc774a;
    --border-color: #424a55;
}

li {
    list-style: none;
}

h1 {
    font-weight: 600;
    font-size: 1.5rem;
}

.wrapper {
    display: flex;
    height: 100vh;
    position: fixed;
    z-index: 9999;
}

.main {
    position: relative;
    transition: all 0.35s ease-in-out;
    overflow: hidden;
}

#sidebar {
    width: 90px;
    min-width: 70px;
    z-index: 1000;
    transition: all .25s ease-in-out;
    background-color: #0e2238;
    display: flex;
    flex-direction: column;
}

.main__wrap {
    padding: 10px 0px 0px 0px;
    /* background-color: var(--bg-body); */
    transition: all .3s ease;
}

.sidebar__wrap.expand+.main__wrap {
    width: calc(100% - 235px);
    margin-left: auto;
}

.sidebar__wrap+.main__wrap {
    width: calc(100% - 95px);
    margin-left: auto;
}

#sidebar.expand {
    width: 232px;
}

.toggle-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}

.toggle-btn i {
    font-size: 1.5rem;
    color: #FFF;
}

.sidebar-logo {
    margin: auto 0;
}

.sidebar-logo a {
    text-decoration: none;
    color: #FFF;
    font-size: 1.15rem;
    text-align: center;
    font-weight: 600;

}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}

.sidebar-nav {
    padding: 1rem 0;
    flex: 1 1 auto;
}

.navlink {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link i {
    font-size: 1.1rem;
    margin-right: .75rem;
}

a.sidebar-link:hover {
    background-color: rgba(255, 255, 255, .075);
    border-left: 3px solid #3b7ddd;
}

.sidebar-item {
    position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #0e2238;
    padding: 0;
    min-width: 15rem;
    display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}


/* login and signup */

/* .login-page {
    width: 25%;
    margin-top: 180px;
} */

.sign-page {
    width: 25%;
    margin-top: 100px;
}

.custom-overflow {
    overflow: hidden;
}

.main__wrap {
    overflow: hidden;
}

.form-controlinput:focus {
    border: none;
    outline: none !important;
}

.bind_details {
    margin: auto !important;
}

.table-sec {
    overflow-x: hidden !important;
    margin-top: 10px;
    background: transparent;
    width: 650px;
    margin: auto;
    max-width: 100%;
    min-height: 100vh;
}



/* .transactions{
overflow-x: scroll !important;

} */
.op {
    width: 1600px !important;
}

.text-red {
    color: red !important;
}

.text-green {
    color: green !important;
}

::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --bg-body: #131720;
    --bg-card: #171f2a;
    --bg-navbar: #212b39;
    --main-text-color: #d2d9e4;
    --primary-color: #e66464;
    --secondary-color: #fc774a;
    --border-color: #424a55;
}

.login-page {
    width: 80%;
    margin-top: 60px;
    background-color: transparent;
    border-radius: 10px;
    /* border: 1px solid #0b83ca; */
}

.sign-page {
    width: 80%;
    margin-top: 10px;
    background-color: transparent;
    border-radius: 10px;
}

.sinup-bg {
    background-color: #151723;
    border: 1px solid #0183cc !important;
    border-radius: 10px;
}

.slideskew {
    -webkit-animation: slideskew 5s ease 0s infinite normal none running;
    animation: slideskew 5s ease 0s infinite normal none running;
}

@keyframes slideskew {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
        -moz-transform: translateY(20px);
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }
}

@keyframes slideskew {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
        -moz-transform: translateY(20px);
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }
}

.main-box-sing {
    background-image: url(https://finlab.dexignlab.com/frontend/react/static/media/bg.0707aafea796bde7f5e2.jpg);
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
}

.btn:hover::after {
    border-right: 7px solid #fff;
    opacity: 0;
    transition: all .5s;
    width: 100%;
}

/* .btn::after {
    background-color: inherit;
    content: "";
    height: 100%;
    left: -12px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 7px;
    z-index: 1;
} */

::selection {
    background: #02a3fe;
    background: var(--primary);
    color: #fff;
}

.btn.btn-primary,
.btn.btn-primary:hover {
    color: #fff;
}

.site-header .extra-nav .extra-cell>* {
    margin-left: 10px;
}

.btn-primary.disabled,
.btn-primary:active,
.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #0183cc;
    background-color: var(--primary-hover);
    border-color: #0183cc;
    border-color: var(--primary-hover);
}

.btn-primary.btn-shadow {
    box-shadow: 0 4px 10px rgba(2, 163, 254, .2);
    box-shadow: 0 4px 10px var(--rgba-primary-2);
}



.icons_name {
    font-size: 26px;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

h1 {
    font-weight: 600;
    font-size: 1.5rem;
}

.wrapper {
    display: flex;
    height: 100vh;
    position: fixed;
    z-index: 9999;
}

.main {
    position: relative;
    transition: all 0.35s ease-in-out;
    overflow: hidden;
}

#sidebar {
    width: 90px;
    min-width: 70px;
    z-index: 1000;
    transition: all .25s ease-in-out;
    background-color: #0e2238;
    display: flex;
    flex-direction: column;
}

.main__wrap {
    padding: 10px 10px 0px 0px;
    /* background-color: var(--bg-body); */
    transition: all .3s ease;
}

.sidebar__wrap.expand+.main__wrap {
    width: calc(100% - 246px);
    margin-left: auto;
}

.sidebar__wrap+.main__wrap {
    width: calc(100% - 106px);
    margin-left: auto;
    overflow: hidden;
}

#sidebar.expand {
    width: 232px;
}

.toggle-btn {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 1rem 1.5rem;
}

.toggle-btn i {
    font-size: 1.5rem;
    color: #FFF;
}

.sidebar-logo {
    margin: auto 0;
}

.sidebar-logo a {
    text-decoration: none;
    color: #FFF;
    font-size: 1.15rem;
    font-weight: 600;
}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span {
    display: none;
}

.sidebar-nav {
    padding: 1rem 0;
    flex: 1 1 auto;
}

.navlink {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link {
    text-decoration: none;
    padding: .625rem 1.625rem;
    color: #fff;
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    border-left: 3px solid transparent;
}

.sidebar-link i {
    font-size: 1.1rem;
    margin-right: .75rem;
}

a.sidebar-link:hover {
    background-color: rgba(255, 255, 255, .075);
    border-left: 3px solid #3b7ddd;
}

.sidebar-item {
    position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: #0e2238;
    padding: 0;
    min-width: 15rem;
    display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown+.sidebar-dropdown {
    display: block;
    max-height: 15em;
    width: 100%;
    opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
    border: solid;
    border-width: 0 .075rem .075rem 0;
    content: "";
    display: inline-block;
    padding: 2px;
    position: absolute;
    right: 1.5rem;
    top: 1.4rem;
    transform: rotate(-135deg);
    transition: all .2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
    transform: rotate(45deg);
    transition: all .2s ease-out;
}

/*  */

.box {
    width: 170px;
    background-image: linear-gradient(90deg, #aca5a5 0%, #aca5a5 35%, #aca5a5 100%) !important;
    padding: 5px;
    text-align: center;
    border-radius: 8px;
}

.icon-box1 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.sec {
    width: 2600px !important;
}

.secs {
    width: 1900px !important;

}

.sec1 {
    width: 1600px !important;
}

.heading {
    margin-bottom: 20px !important;
    color: #02a3fe !important;
    /* font-size: ; */
}

.heading-data {
    color: white;
}

.H_3 {
    font-size: 25px !important;

}

.ls {
    margin-top: 60px !important;
}

h3.H_3.email {
    margin-top: 30px;
    color: #02a3fe;
}

/* 5 */
.btn-5 {
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: 1px solid #02a3fe;
    background-color: transparent;
    box-shadow: 0 3px 1px 0 #02a3fe;
}

.btn-5:hover {
    color: #02a3fe;
    background: transparent;
    box-shadow: none;
}

.btn-5:before,
.btn-5:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #02a3fe;
    box-shadow:
        -1px -1px 5px 0px #fff,
        7px 7px 20px 0px #0003,
        4px 4px 5px 0px #0002;
    transition: 400ms ease all;
}

.btn-5:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
    width: 100%;
    transition: 800ms ease all;
}

.smalls {
    font-size: 12px !important;
    margin-top: 20px !important;
}

.acont {
    font-size: 32px !important;

}

.iop {
    height: 40px;
    /* width: 90x; */
    /* font-size: 10px !important; */
}

.butt {
    display: flex;
    gap: 50px;
}


.highlighted-text {
    background-color: #02a3fe;
    border-radius: 3px;
}

@media (min-width:320px) and (max-width:767px) {
    .login-page {
        width: 90%;
        margin-top: 30px;
    }

    .heading {
        margin-bottom: 30px !important;
        /* font-size: ; */
    }

    .sign-page {
        width: 90%;
        margin-top: 10px;
    }

    /* i.fas.fa-eye-slash {
        position: relative;
        bottom: 33px;
        right: 10px;
        color: white;
    } */


    .H_3 {
        font-size: 22px !important;
    }

    .acont {
        font-size: 20px !important;
    }

    /* .ls{
        margin-top: 174px !important;
    } */
    .iop {
        height: 35px;
        width: 90px;
        /* font-size: 10px !important; */
    }






    .rounded {
        margin: 0;
        /* Ensure no margin on mobile to use the full screen */
        box-shadow: none;
        /* Optional: Remove shadow to make it flatter */
    }

    .p-3 {
        padding: 5px;
        /* Reduce padding for a more compact layout */
    }

    .vr {
        display: none;
        /* Hide vertical divider on smaller screens */
    }

    .d-flex {
        /* flex-direction: column; */
        /* Stack elements vertically on mobile */
        /* align-items: flex-start; */
        /* Align items to the left */
    }

    .cr {
        margin-top: 15px;
        /* Add space between sections */
    }

    .itd {
        text-align: center;

    }


    .uio :is(h5, h6) {
        font-size: 14px;
        /* Adjust font size for mobile */
    }

    hr {
        display: none;
    }

    .sidebar-logo a {
        text-decoration: none;
        color: #FFF;
        font-size: 1.15rem;
        font-weight: 600;
        text-align: center !important;
    }

    .w-full {
        width: 100% !important;
    }

}

.navbar_u {
    box-shadow: 0 8px 24px #959da533;
    background: #151723;
}



button.btn_signup {
    border: none;
    border-radius: 3px;
    color: #fff;
    margin: auto;
    padding: 2px 5px;
    width: 95px;
    background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
}

a.hov_links {
    color: #00a5f0;
    display: flex;
    font-family: Mukta, sans-serif;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    margin: auto;
}