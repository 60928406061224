* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    background: #151723 !important;
}

.navbar_u {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

button.sidebar_btn {
    padding: 6px;
    border: none;
    width: 43px;
    border-radius: 10px;
    background: #fff;
    display: none;
}

ul.navbar-nav li a:hover {
    transition: all .3s;
    color: #0280fa;
}

.heading_h1.fw-bold {
    color: #00a1f1;
}

.text-muted {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.75) !important;
}

/* a.hov_links {
    color: #00b093;
    font-family: "Mukta", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: auto;
    display: flex;
    justify-content: center;
} */
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: rgb(255 255 255) !important;
}

.foreign_exchange {
    background-attachment: fixed;
    background-image: url(https://finlab.dexignlab.com/frontend/react/static/media/bg.0707aafea796bde7f5e2.jpg);
    background-size: cover;
}

a.hov_links::after {
    content: " ";
    height: 2px;
    width: 0%;
    display: block;
    background: #fff;
    transition: .5s ease-in-out;
}

a.hove_links:hover::after {
    content: " ";
    height: 2px;
    width: 100%;
    display: block;
}

button.btn_signup {
    background-color: #00a1f1;
    color: #fff;
    padding: 2px;
    width: 95px;
    border: none;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
}

.animation-img {
    animation: slideskew 5s ease 0s infinite normal none running;
}

.bg-off-white {
    background-color: #f4f8ff;
}

.pb-70 {
    padding-bottom: 70px;
}

.pt-100 {
    padding-top: 92px;
}

section.future-trade {
    background: #151723;
}

.primary_cl {
    color: white;
}

h3.Sentiment {
    font-size: 20px;
    color: #00a1f1;
}

@media screen and (max-width: 992px) {
    button.sidebar_btn {
        display: block;
    }
}